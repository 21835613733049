import './util/modernizr';
import './util/polyfills';
import '../style.scss';
import './core/core';

// import 'magnific-popup';
/*
 * Atoms
 */
import floatingCta from '../patterns/01-atoms/buttons/button-floating-cta/button-floating-cta';
import scrollToTop from '../patterns/01-atoms/scroll-to-top/scroll-to-top';
/*
 * Molecules
 */
import showAccordion from '../patterns/02-molecules/accordions/accordion/accordion';
import debugControls from '../patterns/02-molecules/debug-controls/debug-controls';
import dropdown from '../patterns/02-molecules/dropdowns/dropdown/dropdown';
import gravityForms from '../patterns/02-molecules/forms/form/gravity-forms';
// import gallery from '../patterns/02-molecules/galleries/gallery/gallery';
import listType2 from '../patterns/02-molecules/lists/list-type-2/list-type-2';
import mapBlock from '../patterns/02-molecules/maps/map/map';
import modal from '../patterns/02-molecules/modals/modal/modal';
import modalVideo from '../patterns/02-molecules/modals/modal-video/modal-video';
import { hamburger } from '../patterns/02-molecules/nav/hamburger/hamburger';
import navigateCategory from '../patterns/02-molecules/nav/nav-category/nav-category';
import navMob from '../patterns/02-molecules/nav/nav-mobile/nav-mobile';
import navPrimary from '../patterns/02-molecules/nav/nav-primary/nav-primary';
import searchBar from '../patterns/02-molecules/search/search-bar/search-bar';
import { slideOut } from '../patterns/02-molecules/slide-outs/slide-out/slide-out';
import sliderImages from '../patterns/02-molecules/sliders/slider-images/slider-images';
import sliderTestimonials from '../patterns/02-molecules/sliders/slider-testimonials/slider-testimonials';
import sliderType1 from '../patterns/02-molecules/sliders/slider-type-1/slider-type-1';
import sliderType2 from '../patterns/02-molecules/sliders/slider-type-2/slider-type-2';
import sliderType3 from '../patterns/02-molecules/sliders/slider-type-3/slider-type-3';
import sliderType4 from '../patterns/02-molecules/sliders/slider-type-4/slider-type-4';
import socialShare from '../patterns/02-molecules/social/social-share/social-share';
import table from '../patterns/02-molecules/tables/table/table';
import tabs from '../patterns/02-molecules/tabs/tabs/tabs';
import btImageTextType3 from '../patterns/03-organisms/custom/bt-image-text-type-3/bt-image-text-type-3';
/*
 * Plop Entry (do not remove)
 */
////DO NOT ERASE OR PLOP IMPORTING WILL FAIL
////IMPORT FROM PLOP
/*
 * Organisms
 */
import header from '../patterns/03-organisms/custom/custom-header/header';
// import defaultFilter from '../patterns/03-organisms/default/default-filter/default-filter';
import BtResourceMenu from '../patterns/03-organisms/default/default-resource-menu/default-resource-menu';
// import defaultSecondaryNavbar from '../patterns/03-organisms/default/default-secondary-navbar/default-secondary-navbar';
// import defaultTeamMembers from '../patterns/03-organisms/default/default-team/default-team';
// import defaultArticleSideNavigation from '../patterns/04-templates/default/default-article-scrolling-nav-single/default-article-scrolling-nav-single';
/*
 * Misc
 */

import externalLinks from './misc/external-links/external-links';
import parallaxScroll from './misc/parallax/parallax-scroll';
import scrollToAnchor from './misc/scroll/scroll-to-anchor';

/*
 * Module Caller
 *
 * functions below are only run if the component exists (verified through a "data-module" in the html)
 * in the ajax container or page. Barba.js instantiates the functions below on container reload as well.
 *
 * Place in alphabetical order
 */
const app = {
	showAccordion,
	sliderType1,
	sliderType2,
	sliderType3,
	sliderType4,
	sliderImages,
	sliderTestimonials,
	header,
	navigateCategory,
	searchBar,
	scrollToTop,
	floatingCta,
	socialShare,
	hamburger,
	slideOut,
	modal,
	modalVideo,
	debugControls,
	tabs,
	listType2,
	mapBlock,
	dropdown,
	// gallery,
	btImageTextType3,
	BtResourceMenu,
};

let calledModulesNames = [];
app.instantiate = function(elem) {
	const $this = $(elem);
	const module = $this.attr('data-module');

	if (module === undefined) {
		throw 'Module not defined (use data-module="")';
	} else if (module in app) {
		if ($this.attr('data-initialized') === 'true') {
			return;
		}
		new app[module](elem);
		$this.attr('data-initialized', true);
	} else {
		throw "Module '" + module + "' not found";
	}
};

jQuery(document).ready(function() {
	scrollToAnchor($);
	navMob($);
	externalLinks($);

	// Component functions that should only run if the component exists in the ajax container or page.
	$('[data-module]').each(function() {
		if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
			app.instantiate(this);

			calledModulesNames.push($(this).data('module'));
		}
	});
});

jQuery(window).scroll(function() {
	parallaxScroll($);
});

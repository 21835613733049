function BtResourceMenu() {
	if ($('.o-default-resource-menu').length) {
		$('.o-default-resource-menu').each(function() {
			var menu = $(this);
			var menu_width = menu
				.find('.o-default-resource-menu__inner')
				.width();
			var menu_items_width = 0;

			$(this)
				.find('.o-default-resource-menu__menu-item')
				.each(function() {
					menu_items_width += $(this).width();
				});

			if (menu_items_width > menu_width) {
				menu.addClass('o-default-resource-menu--mobile');
			}

			setTimeout(function() {
				menu.addClass('o-default-resource-menu--init');
			}, 300);

			$(window).resize(function() {
				menu_width = menu
					.find('.o-default-resource-menu__inner')
					.width();

				if (menu_items_width > menu_width) {
					menu.addClass('o-default-resource-menu--mobile');
				} else {
					menu.removeClass('o-default-resource-menu--mobile');
				}
			});
		});
	}
}

export default BtResourceMenu;

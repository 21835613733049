import MicroModal from 'micromodal';

export default () => {
	MicroModal.init({
		disableScroll: true,
		awaitOpenAnimation: false,
		awaitCloseAnimation: true,
		onClose: modal => {
			modal.querySelectorAll('iframe').forEach(iframe => {
				iframe.setAttribute('src', iframe.getAttribute('src'));
			});
		},
	});
};

/**
 * PictureFill + LazySizes + WOW
 *
 * NOTE: You should not need to modify
 * this file!
 *
 * The libraries listed below are used in almost ALL DOD projects.
 */

/**
 * Responsive Images Polyfill
 */
import 'picturefill';
/**
 * Lazyload images: https://github.com/aFarkas/lazysizes
 */
import 'lazysizes';

/**
 * Reveal CSS animation as a user scrolls down a page: https://github.com/matthieua/WOW
 */
import WOW from 'wow.js/dist/wow.js';

/**
 * Get env variables.
 */
const { DEV_MODE_DISABLE_WOW } = process.env;

if (!DEV_MODE_DISABLE_WOW || !parseInt(DEV_MODE_DISABLE_WOW)) {
	/**
	 * Init WOW. Accepts parameters
	 */
	new WOW().init();
}

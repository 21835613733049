/*
 * ScrollToTop
 *
 * runs the functionality of the scroll to top button that appears at the bottom right of the website
 */
/* eslint-disable */

let target = '.js-list-type-2-trigger';
let list = '.m-list-type-2';
let listActive = 'm-list-type-2--active';

function listType2() {
	// Toggle CSS class on an element on click
	$(target).on('click', function(e) {
		e.preventDefault();
		$(list).toggleClass(listActive); //you can list several class names
	});
}

export default listType2;

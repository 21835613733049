$('.gform_wrapper .provider-application .gchoice label').addClass(
	'gchoice-label',
);
$('.gform_wrapper .provider-application .gchoice label').wrapInner(
	'<span class="gchoice-label__title"></span>',
);
$('.gform_wrapper .provider-application .gchoice label').prepend(
	'<span class="gchoice-label__toggler"></span>',
);

$('.gform_wrapper .provider-application .gfield_consent_label').addClass(
	'gchoice-label',
);
$('.gform_wrapper .provider-application .gfield_consent_label').wrapInner(
	'<span class="gchoice-label__title"></span>',
);
$('.gform_wrapper .provider-application .gfield_consent_label').prepend(
	'<span class="gchoice-label__toggler"></span>',
);

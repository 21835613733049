import Swiper, { Autoplay, Pagination } from 'swiper';

function sliderType3() {
	Swiper.use([Autoplay, Pagination]);

	// Breakpoint where swiper will be destroyed and switches to a single-column layout
	const breakpoint = window.matchMedia('(min-width: 768px)');

	// Keep track of swiper instances to destroy later
	let swiperSliderTypes = [];

	const breakpointChecker = () => {
		// If larger viewport and multi-row layout needed
		if (breakpoint.matches) {
			// Clean up old instances and inline styles when available
			if (swiperSliderTypes !== []) {
				swiperSliderTypes.forEach(type => {
					if (type) {
						type.destroy(true, true);
					}
				});
			}

			// Or/and do nothing
			return;

			// Else if a small viewport and single column layout needed
		} else if (!breakpoint.matches) {
			// Fire small viewport version of swiper
			return enableSwiper();
		}
	};

	const enableSwiper = function() {
		$('.js-slider-swiper-type-3').each(function(index) {
			$(this).addClass(`js-slider-swiper-type-3-${index}`);

			swiperSliderTypes.push(
				new Swiper(`.js-slider-swiper-type-3-${index}`, {
					loop: false,
					grabCursor: true,
					speed: 600,
					autoplay: {
						delay: 7000,
					},
					slidesPerView: 1,
					spaceBetween: 16,
					pagination: {
						el: '.js-slider-swiper-type-3-pagination',
						type: 'bullets',
						clickable: true,
					},
				}),
			);
		});
	};

	// Keep an eye on viewport size changes
	breakpoint.onchange = breakpointChecker;

	// Kickstart
	breakpointChecker();
}

export default sliderType3;

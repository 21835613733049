import Swiper, { Autoplay, Pagination, EffectFade } from 'swiper';

function sliderType4() {
	Swiper.use([Autoplay, Pagination, EffectFade]);

	let swiperSliderTypes = [];
	let swiperSliderNestedTypes = [];

	const swiperDefaultOptions = {
		loop: false,
		speed: 600,
		slidesPerView: 1,
		spaceBetween: 0,
	};

	$('.js-slider-swiper-type-4').each(function(index) {
		if ($(this).find('.m-slider-type-4__slide-item').length <= 1) {
			return;
		}

		$(this).addClass(`js-slider-swiper-type-4-${index}`);

		swiperSliderTypes.push(
			new Swiper(`.js-slider-swiper-type-4-${index}`, {
				...swiperDefaultOptions,
				grabCursor: true,
				pagination: {
					el: '.js-slider-swiper-type-4-pagination',
					type: 'bullets',
					clickable: true,
				},
			}),
		);
	});

	$('.js-slider-swiper-type-4--nested').each(function(index) {
		$(this).addClass(`js-slider-swiper-type-4--nested-${index}`);

		swiperSliderNestedTypes.push(
			new Swiper(`.js-slider-swiper-type-4--nested-${index}`, {
				...swiperDefaultOptions,
				nested: true,
				direction: 'horizontal',
				effect: 'fade',
				fadeEffect: {
					crossFade: true,
				},
				autoplay: {
					delay: 7000,
				},
				pagination: false,
			}),
		);
	});
}

export default sliderType4;

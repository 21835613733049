import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function sliderType2() {
	Swiper.use([Autoplay, Navigation, Pagination]);

	// Ensuring we don't show buttons if there is just 1 entry outputted
	if ($('.swiper-slide').length <= 3) {
		$('.m-slider-type-2__wrapper').addClass(
			'm-slider-type-2__wrapper--disabled',
		);

		$('.js-slider-swiper-type-2-btn-prev').addClass(
			'js-slider-swiper-type-2-btn-prev--disabled',
		);
		$('.js-slider-swiper-type-2-btn-next').addClass(
			'js-slider-swiper-type-2-btn-next--disabled',
		);
	} else {
		$('.m-slider-type-2__wrapper').removeClass(
			'm-slider-type-2__wrapper--disabled',
		);

		$('.js-slider-swiper-type-2-btn-prev').removeClass(
			'js-slider-swiper-type-2-btn-prev--disabled',
		);
		$('.js-slider-swiper-type-2-btn-next').removeClass(
			'js-slider-swiper-type-2-btn-next--disabled',
		);

		new Swiper('.js-slider-swiper-type-2', {
			loop: false,
			grabCursor: true,
			speed: 500,
			autoplay: {
				delay: 7000,
			},
			slidesPerView: 1,
			spaceBetween: 8,
			navigation: {
				prevEl: '.js-slider-swiper-type-2-btn-prev',
				nextEl: '.js-slider-swiper-type-2-btn-next',
			},
			pagination: {
				el: '.js-slider-swiper-type-2-pagination',
				type: 'bullets',
				clickable: true,
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
				},
			},
		});
	}
}

export default sliderType2;

function btImageTextType3() {
	$('.o-bt-image-text-type-3__btn--video').on('click', function(e) {
		e.preventDefault();
		$(this)
			.closest('.o-bt-image-text-type-3__group-text')
			.siblings('.o-bt-image-text-type-3__media-wrapper')
			.children('.m-modal-video__link')
			.trigger('click');
	});
}

export default btImageTextType3;

var timer;

$('.m-nav--primary .m-nav__item.menu-item-has-children').hover(
	function() {
		if (timer) {
			clearTimeout(timer);
		}

		var item = $(this);

		item.siblings('.m-nav__item.menu-item-has-children').removeClass(
			'opened',
		);
		item.addClass('opened');

		timer = setTimeout(function() {
			timer = 0;
			item.addClass('animated');
		}, 100);
	},

	function() {
		if (timer) {
			clearTimeout(timer);
		}

		var item = $(this);

		item.removeClass('animated');

		timer = setTimeout(function() {
			timer = 0;
			item.removeClass('opened');
		}, 250);
	},
);

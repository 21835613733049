//////////////////////////////////////////////////
//                                              //
//  Molecule: Table                             //
//                                              //
//  https://www.dreamersofday.co                //
//                                              //
//////////////////////////////////////////////////

/**
 * Wrap all the tables ( excluding .not-scroll  ) to container with controls
 * -------------------------------------------------------------------------
 */

$('table:not(.not-scroll)').each(function() {
	$(this).wrap(
		"<div class='bt-table-wrap'><div class='bt-table-wrap__inner'><div class='bt-table-wrap__scroll'></div></div></div>",
	);
});

$('.bt-table-wrap').append(
	'<div class="bt-table-wrap__controls"><button class="bt-table-wrap__control bt-table-wrap__control--scroll-left a-btn a-btn--type-1"><i class="fa fa-chevron-left" aria-hidden="true"></i></button> <button class="bt-table-wrap__control bt-table-wrap__control--scroll-right a-btn a-btn--type-1">Scroll the table &nbsp; <i class="fa fa-chevron-right" aria-hidden="true"></i></button></div>',
);

/**
 * Check if tables has a bigger width than container, if yes enable scroll and controls
 * ------------------------------------------------------------------------------------
 */

function table_overflow_check() {
	$('.bt-table-wrap').each(function() {
		var item_width = $(this).width();
		var item_table_width = $(this)
			.find('table')
			.width();

		if (item_width < item_table_width) {
			$(this).addClass('bt-table-wrap--overflow');
		} else {
			$(this).removeClass('bt-table-wrap--overflow');
		}
	});
}

table_overflow_check();

$(window).resize(function() {
	table_overflow_check();
});

/**
 * Scroll the tabled after control button is clicked
 * ------------------------------------------------------------------------------------
 */

$('.bt-table-wrap__control').on('click', function() {
	var item_table_scroll = $(this)
		.closest('.bt-table-wrap')
		.find('.bt-table-wrap__scroll');

	var item_table_scroll_pos = item_table_scroll.scrollLeft();

	var scroll_offset = $(this).hasClass('bt-table-wrap__control--scroll-left')
		? -150
		: 150;

	item_table_scroll.animate(
		{ scrollLeft: item_table_scroll_pos + scroll_offset },
		150,
	);
});
